.parentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.content {
  position: absolute;
  top: 50%;
  height: 160px;
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  font-size: 35px;
  line-height: 40px;
  color: #ecf0f1;
}

.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
  padding: 0 40px;
}

.content__container:before {
  content: '[';
  position: absolute;
  left: 0;
  top: 0;
  color: #16a085;
  font-size: 42px;
  line-height: 40px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.content__container:after {
  content: ']';
  position: absolute;
  right: 0;
  top: 0;
  color: #16a085;
  font-size: 42px;
  line-height: 40px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.content__container__text {
  display: inline;
  float: left;
  margin: 0;
}

.content__container__list {
  margin-top: 0;
  padding-left: 110px;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.content__container__list__item {
  line-height: 40px;
  margin: 0;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%, 8.47%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10.17%, 18.64% {
    transform: translate3d(0, -16.666%, 0);
  }
  20.34%, 28.81% {
    transform: translate3d(0, -33.333%, 0);
  }
  30.51%, 38.98% {
    transform: translate3d(0, -50%, 0);
  }
  40.68%, 49.15% {
    transform: translate3d(0, -66.666%, 0);
  }
  50.85%, 59.32% {
    transform: translate3d(0, -83.333%, 0);
  }
  61.02%, 69.49% {
    transform: translate3d(0, -66.666%, 0);
  }
  71.19%, 79.66% {
    transform: translate3d(0, -50%, 0);
  }
  81.36%, 89.83% {
    transform: translate3d(0, -33.333%, 0);
  }
  91.53%, 99.7% {
    transform: translate3d(0, -16.666%, 0);
  }
}

@-webkit-keyframeskeyframes change {
  0%, 8.47%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10.17%, 18.64% {
    transform: translate3d(0, -16.666%, 0);
  }
  20.34%, 28.81% {
    transform: translate3d(0, -33.333%, 0);
  }
  30.51%, 38.98% {
    transform: translate3d(0, -50%, 0);
  }
  40.68%, 49.15% {
    transform: translate3d(0, -66.666%, 0);
  }
  50.85%, 59.32% {
    transform: translate3d(0, -83.333%, 0);
  }
  61.02%, 69.49% {
    transform: translate3d(0, -66.666%, 0);
  }
  71.19%, 79.66% {
    transform: translate3d(0, -50%, 0);
  }
  81.36%, 89.83% {
    transform: translate3d(0, -33.333%, 0);
  }
  91.53%, 99.7% {
    transform: translate3d(0, -16.666%, 0);
  }
}

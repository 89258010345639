@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400&display=swap');

.merriweather-font {
  font-family: 'Merriweather', serif;
}

.custom-markdown .prose p,
.custom-markdown .prose li,
.custom-markdown .prose h1,
.custom-markdown .prose h2,
.custom-markdown .prose h3,
.custom-markdown .prose h4,
.custom-markdown .prose h5,
.custom-markdown .prose h6 {
  color: #f1f3f5;
}

#black {
  width: 100%;
  background-color: #111;
}

#dvd {
  position: absolute;
  left: 70px;
  top: 60px;
  height: 50px;
  width: 100px;
  mask: url("https://upload.wikimedia.org/wikipedia/commons/9/9b/DVD_logo.svg");
  -webkit-mask: url("https://upload.wikimedia.org/wikipedia/commons/9/9b/DVD_logo.svg");
  background-repeat: no-repeat;
  background-size: 75px;
  background-position: center;
}


html,
body {
  background-color: #0c0c0c;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flip-card {
  perspective: 1000px;
  width: 16rem;
  height: 24rem;
  display: flex;
  justify-content: center;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-back {
  transform: rotateY(180deg);
}